import * as React from 'react'
import { container, closeViewer, videoContainer } from './photos.module.css'

const VideoViewer = ({ video }) => {
  const hideViewer = () => {
    const isBrowser = () => typeof document !== "undefined";
    if (isBrowser()) {
      document.querySelector("#videoViewer").style.display = "none";
      document.querySelector("#videoViewer").querySelector("iframe").setAttribute("src",video);
    }
  }
  const keys = (e) => {
    if (e.code==="Escape") { hideViewer(); }
  }

  return (
    <div className={container} id="videoViewer" onKeyDown={keys} tabIndex="0" role="button">
      <div className={closeViewer} onClick={hideViewer} role="presentation"><svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M10,10 90,90 M10,90 90,10" />
      </svg></div>
      <div className={videoContainer}>
        <iframe width="100%" height="100%" src={video} frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen onKeyDown={keys} tabIndex="0" title="Video" role="presentation"></iframe>
      </div>
    </div>
  )
}

export default VideoViewer
