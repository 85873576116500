import * as React from 'react'
import { useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import PhotoViewer from '../../components/photos'
import VideoViewer from '../../components/video'
import BookletViewer from '../../components/booklet'
import TextViewer from '../../components/text'
import BackArrow from '../../components/back-arrow'
import { updateProgress } from '../../components/progress'
import { container, infoMenu, activeItem, mobileMenu, albumCover, description, discFont, songTitle, songArtist,
  smallPlay, nowPlaying, wasPaused, pressLink, mediaBox, mediaIcon } from '../../components/albums.module.css'

const AlbumPage = ({ data }) => {
  const isBrowser = () => typeof document !== "undefined";
  //const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  const isWindow = () => typeof window !== "undefined";
  const isMobile = isWindow() && window.matchMedia("(max-width: 700px)").matches;
  const mobileDisplay = isMobile ? "none" : "table-cell";

  const getDuration = (e) => {
    let time = e.target.duration; let string;
    if ((Math.round(time))%60 < 10) {
      string = Math.floor(Math.round(time)/60) + ":0" + (Math.round(time))%60;
    } else {
      string = Math.floor(Math.round(time)/60) + ":" + (Math.round(time))%60;
    } e.target.parentNode.innerHTML = string;
  }

  const updateQueue = (upNext) => {
    if (isBrowser()) {
      // update the names in the queue box
      let ul = document.querySelector("#queue ul");
      while (ul.firstChild) { ul.removeChild(ul.firstChild); }
      if (upNext.length>0) {
        for (let i=0; i<upNext.length; i++) {
          let li = document.createElement("li");
          li.appendChild(document.createTextNode(upNext[i].title));
          ul.appendChild(li);
        }
      } else {
        let li = document.createElement("li");
        li.appendChild(document.createTextNode("No songs in queue."));
        ul.appendChild(li);
      }
    }
  }

  const sendToQueue = (song,e) => {
    if (isBrowser()) {
    // break if click is on info button
    if (e.target.innerHTML==="•••") {return}
    // send new song to audio el to queue
    const startSong = () => {
      // remove audio element of any currently playing song
      if (document.querySelector("#queue").querySelector("audio")!==null)
      {document.querySelector("#queue").querySelector("audio").remove();}
      // assign upcoming songs to queue object array
      let allSongs = data.mdx.exports.songs; let upNext=[], prev=[]; let found=false;
      for (let i=0; i<allSongs.length; i++) {
        if (found) {upNext.push(allSongs[i]);} else {prev.push(allSongs[i]);}
        if (song.title===allSongs[i].title) {found=true;}
      } document.querySelector("#queue").upNext=upNext;
      document.querySelector("#queue").previous=prev;
      updateQueue(upNext);
      let sound = new Audio(song.path.publicURL);
      document.querySelector("#currentTitle").innerHTML = song.title;
      document.querySelector("#currentArtist").innerHTML = song.artist;
      //console.log(getImage(data.mdx.frontmatter.cover));
      document.querySelector("#playDiv").title = "Pause";
      document.querySelector("#playDiv").firstChild.firstChild.setAttribute('d','M17,10 17,90 37,90 37,10 z M63,10 63,90 83,90 83,10 z');
      document.querySelector("#queue").appendChild(sound);
      updateProgress(document.querySelector("#queue").querySelector("audio"));
    };
    // state dependent changes
    let activeTr = document.getElementsByClassName(nowPlaying);
    for (let i=0; i<activeTr.length; i++) {activeTr[i].classList.remove(nowPlaying);}
    if (e.currentTarget.nodeName==="TR") {
      e.currentTarget.classList.add(nowPlaying);
      e.currentTarget.querySelector("div").title = "Pause "+e.currentTarget.querySelector("div").title.substring(5);
      startSong();
    } else if (e.currentTarget.nodeName==="TD") {
      e.currentTarget.parentNode.classList.add(nowPlaying);
      let isNotAlreadyPlaying = (document.querySelector("#queue").querySelector("audio")!==null) ? (document.querySelector("#queue").querySelector("audio").getAttribute("src")!==song.path.publicURL) : true;
      if (e.currentTarget.querySelector("div").title.substring(0,4)==="Play" && isNotAlreadyPlaying) {
        e.currentTarget.querySelector("div").title = "Pause "+e.currentTarget.querySelector("div").title.substring(5);
        startSong();
      } else if (e.currentTarget.querySelector("div").title.substring(0,5)==="Pause") {
        e.currentTarget.classList.add(wasPaused);
        e.currentTarget.querySelector("div").title = "Play "+e.currentTarget.querySelector("div").title.substring(6);
        if (document.querySelector("#queue").querySelector("audio")!==null)
        {document.querySelector("#queue").querySelector("audio").pause();}
        document.querySelector("#playDiv").title = "Play";
        document.querySelector("#playDiv").firstChild.firstChild.setAttribute('d','M32,10 32,90 98,50 z');
      } else {
        e.currentTarget.classList.remove(wasPaused);
        e.currentTarget.querySelector("div").title = "Pause "+e.currentTarget.querySelector("div").title.substring(5);
        if (document.querySelector("#queue").querySelector("audio")!==null)
        {document.querySelector("#queue").querySelector("audio").play();}
        document.querySelector("#playDiv").title = "Pause";
        document.querySelector("#playDiv").firstChild.firstChild.setAttribute('d','M17,10 17,90 37,90 37,10 z M63,10 63,90 83,90 83,10 z');
      }
    }
    }
  }

  const activeFormat = (song) => {
    if (isBrowser()) {
    let path; if (document.querySelector("audio")!==null)
    {path = document.querySelector("audio").getAttribute("src");}
    return (path===song.path.publicURL) ? nowPlaying : ""
    }
  }

  let discs = data.mdx.frontmatter.discs;
  let album
    = (discs.length>0)
    ? [data.mdx.exports.songs.slice(0,parseInt(data.mdx.frontmatter.discs[0])),
       data.mdx.exports.songs.slice(parseInt(data.mdx.frontmatter.discs[0]))]
    : [data.mdx.exports.songs]
  ;
  let discHeader = (n) => {return (discs.length>0&&(!isMobile)) ? <tr className={discFont}><td></td><td><mark>&#10686;</mark> {n+1}: {data.mdx.frontmatter.subtitles[n]}</td></tr> : <></>;}

  const showMenu = (e) => {
    if (isBrowser()) {
    e.preventDefault();
    // set up clickability
    let allRows = [].slice.call(document.querySelectorAll('tbody > tr'), 0);
    let rows=[]; for (let i=0;i<allRows.length;i++) {if(allRows[i].className!==discFont){rows.push(allRows[i])}}
    let index = (rows.indexOf(e.currentTarget)===-1) ? rows.indexOf(e.currentTarget.parentElement) : rows.indexOf(e.currentTarget);
    if (data.mdx.exports.songs[index].lyrics) {document.querySelector("#showLyrics").classList.add(activeItem)}
    else {document.querySelector("#showLyrics").classList.remove(activeItem)}
    if (data.mdx.exports.songs[index].video) {document.querySelector("#showSongVideo").classList.add(activeItem)}
    else {document.querySelector("#showSongVideo").classList.remove(activeItem)}
    if (data.mdx.frontmatter.title==="Covers") {
      document.querySelector("#getDownload").classList.add(activeItem);
      document.querySelector("#getDownload").innerHTML = `<a href="${data.mdx.exports.songs[index].path.publicURL}" target="_blank">Download</a>`;
    } else {
      document.querySelector("#getDownload").classList.remove(activeItem);
      document.querySelector("#getDownload").innerHTML = 'Download';
    }
    if (data.mdx.exports.songs[index].score) {document.querySelector("#showScore").classList.add(activeItem)}
    else {document.querySelector("#showScore").classList.remove(activeItem)}
    if (data.mdx.exports.songs[index].info) {document.querySelector("#showInfo").classList.add(activeItem)}
    else {document.querySelector("#showInfo").classList.remove(activeItem)}
    // set up placement
    if (!isMobile) {
      let x = e.clientX, y = e.clientY;
      let boxX = document.querySelector("#infoMenu").clientWidth, boxY = document.querySelector("#infoMenu").clientHeight;
      let windowX = window.innerWidth, windowY = window.innerHeight;
      let scrollX = window.pageXOffset, scrollY = window.pageYOffset;
      let offsetX = (x+boxX>windowX) ? -boxX : 0, offsetY = (y+boxY>windowY) ? -boxY : 0;
      document.querySelector("#infoMenu").style.left = (x+scrollX+offsetX)+"px";
      document.querySelector("#infoMenu").style.top = (y+scrollY+offsetY)+"px";
    } else {
      document.querySelector("#infoMenu").classList.add(mobileMenu);
      document.querySelector("#closeMenu").style.display = "block";
    }
    document.querySelector("#infoMenu").style.display = "block";
    document.querySelector("#infoMenu").index = index;
    }
  }
  const Menu = () => {
    const showLyrics = () => { if (isBrowser()) {
      if (document.querySelector("#showLyrics").classList.contains(activeItem)) {
        let songIndex = document.querySelector("#infoMenu").index;
        let songData = data.mdx.exports.songs[songIndex];
        setTextSrc(songData.lyrics.childPlainText.content);
        setTextTitle(songData.title);
        document.getElementById("textViewer").scrollTop = 0;
        document.querySelector("#textViewer").style.display = "block";
        document.querySelector("#textViewer").focus();
      }}
    }
    const showSongVideo = () => { if (isBrowser()) {
      if (document.querySelector("#showSongVideo").classList.contains(activeItem)) {
        let songIndex = document.querySelector("#infoMenu").index;
        let songVideo = data.mdx.exports.songs[songIndex].video;
        setVideoSrc(songVideo);
        document.querySelector("#videoViewer").style.display = "block";
        document.querySelector("#videoViewer").focus();
      }}
    }
    const showScore = () => { if (isBrowser()) {
      if (document.querySelector("#showSongVideo").classList.contains(activeItem)) {
        let songIndex = document.querySelector("#infoMenu").index;
        let score = data.mdx.exports.songs[songIndex].score.publicURL;
        setPdfSrc(score);
        document.querySelector("#bookletViewer").style.display = "block";
        document.querySelector("#bookletViewer").focus();
      }}
    }
    const showInfo = () => { if (isBrowser()) {
      if (document.querySelector("#showInfo").classList.contains(activeItem)) {
        let songIndex = document.querySelector("#infoMenu").index;
        let songData = data.mdx.exports.songs[songIndex];
        setTextSrc(songData.info.childPlainText.content);
        setTextTitle("");
        document.querySelector("#textViewer").style.display = "block";
        document.querySelector("#textViewer").focus();
      }}
    }

    return (
      <div className={infoMenu} id="infoMenu" style={{display:"none"}}>
        <ul>
          <li id="showLyrics" onClick={showLyrics} role="presentation">Lyrics</li>
          <li id="showSongVideo" onClick={showSongVideo} role="presentation">Video</li>
          <li id="showScore" onClick={showScore} role="presentation">Sheet music</li>
          <li id="getDownload">Download</li>
          <li id="showInfo" onClick={showInfo} role="presentation">More info</li>
          <li id="closeMenu" style={{display:"none"}} className={activeItem}>Close</li>
        </ul>
      </div>
    )
  }

  const [videoSrc,setVideoSrc] = useState(data.mdx.frontmatter.video);
  const [pdfSrc,setPdfSrc] = useState((data.mdx.frontmatter.booklet!==null) ? data.mdx.frontmatter.booklet.publicURL : "");
  const [textSrc,setTextSrc] = useState("");
  const [textTitle,setTextTitle] = useState("");

  const showPhotoViewer = () => {
    if (isBrowser()) {
    document.querySelector("#photoViewer").style.display = "block";
    document.querySelector("#photoViewer").focus();}
  }
  const showVideoViewer = () => {
    if (isBrowser()) {
    document.querySelector("#videoViewer").style.display = "block";
    document.querySelector("#videoViewer").focus();}
  }
  const showBookletViewer = () => {
    if (isBrowser()) {
    document.querySelector("#bookletViewer").style.display = "block";
    document.querySelector("#bookletViewer").focus();}
  }


  return (
    <div><div className={container}>
      <title>{data.mdx.frontmatter.title} &ndash; Evan Gedrich</title>
      <Menu></Menu>
      {(data.mdx.frontmatter.images.length>0) ? <PhotoViewer images={data.mdx.frontmatter.images}></PhotoViewer> : <></>}
      <VideoViewer video={videoSrc}></VideoViewer>
      <BookletViewer booklet={pdfSrc}></BookletViewer>
      <TextViewer text={textSrc} title={textTitle}></TextViewer>
      <GatsbyImage image={getImage(data.mdx.frontmatter.cover)} alt="Album Cover" className={albumCover} loading="eager"></GatsbyImage>
      <div className={description}>
        <h2>{data.mdx.frontmatter.type}</h2>
        <h1>{data.mdx.frontmatter.title}</h1>
        <p>
          {data.mdx.frontmatter.blurb}
          {data.mdx.frontmatter.press.map((link,i) => (
            <a href={link} key={link} className={pressLink} target="_blank" rel="noreferrer"><sup>[{i+1}]</sup></a>
          ))}
        </p>
        <h3>{data.mdx.exports.songs.length} songs &bull; {data.mdx.frontmatter.year}</h3>
        <div className={mediaBox}>
          {(data.mdx.frontmatter.images.length>0) ?
          <div className={mediaIcon} title="Photos" onClick={showPhotoViewer} role="presentation">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <rect width="90" height="64" x="5" y="18" />
              <path d="M5,65 22.5,50 40,65 67.5,40 95,65"/>
              <circle cx="40" cy="40" r="5" />
            </svg>
          </div>
          : <></>
          }
          {(data.mdx.frontmatter.video!=="") ?
          <div className={mediaIcon} title="Video" onClick={showVideoViewer} role="presentation">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <rect width="90" height="64" x="5" y="18" />
              <path d="M39,35 39,65 63,50z" />
            </svg>
          </div>
          : <></>
          }
          {(data.mdx.frontmatter.booklet!==null) ?
          <div className={mediaIcon} title="Booklet" onClick={showBookletViewer} role="presentation">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <path d="M5,10 5,75 50,90 95,75 95,10 50,25z M50,25 50,90 M60,40 85,30 M60,55 85,45 M60,70 85,60"/>
            </svg>
          </div>
          : <></>
          }
        </div>
      </div>
      <table>
        <thead><tr>
          <th style={{width:"3%",textAlign:"right",display:mobileDisplay}}>#</th>
          <th style={{width:"60%",textAlign:"left"}}>Song</th>
          <th style={{width:"22%",textAlign:"right",display:mobileDisplay}}>Composer</th>
          <th style={{width:"10%",textAlign:"right",display:mobileDisplay}}>Time</th>
          <th style={{width:"5%",textAlign:"right"}}>&nbsp;</th>
        </tr><tr style={{width:"100%",height:"8px"}}></tr></thead>
        <tbody>
          {album.map((disc,j) => (
              <React.Fragment key={`disc${j}`}>{discHeader(j)}
            {disc.map((song,i) => (
              <tr key={song.title} onDoubleClick={(e)=>(sendToQueue(song,e))} onClick={(isMobile)?(e=>sendToQueue(song,e)):null} onContextMenu={showMenu} className={activeFormat(song)}>
                <td style={{textAlign:"right",display:mobileDisplay}} onClick={(e)=>(sendToQueue(song,e))} role="presentation">
                  <span>{i+1}</span>
                  <div className={smallPlay} title={`Play ${song.title}`}>
                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <path d="M32,10 32,90 98,50 z" stroke="var(--color-front)" strokeWidth="6" fill="transparent"></path>
                    </svg>
                  </div>
                </td>
                <td style={{textAlign:"left"}}><div className={songTitle}>{song.title}</div><div className={songArtist}>{song.artist}</div></td>
                <td style={{textAlign:"right",display:mobileDisplay}}>{song.composer}</td>
                <td style={{textAlign:"right",display:mobileDisplay}}><audio src={song.path.publicURL} onLoadedMetadata={getDuration}/>{Math.floor(Math.random()*10)+":"+Math.floor(Math.random()*50+10)}</td>
                <td style={{textAlign:"right",paddingRight:"10px"}} onClick={showMenu} role="presentation">&bull;&bull;&bull;</td>
              </tr>
            ))}</React.Fragment>
          ))}
        </tbody>
      </table>
      <MDXRenderer>{data.mdx.body}</MDXRenderer>
    </div>
    <BackArrow to="/music">Music</BackArrow></div>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        year
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
            )
          }
        }
        type
        blurb
        press
        discs
        subtitles
        images {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
        video
        booklet {
          publicURL
        }
      }
      exports {
        songs {
          title
          artist
          composer
          path {
            publicURL
          }
          lyrics  {
            childPlainText {
              content
            }
          }
          video
          score {
            publicURL
          }
          info  {
            childPlainText {
              content
            }
          }
        }
      }
      body
    }
  }
`

export default AlbumPage
