// extracted by mini-css-extract-plugin
export var container = "photos-module--container--ZvOco";
export var nextArrow = "photos-module--next-arrow--HabkX";
export var prevArrow = "photos-module--prev-arrow--WOzqm";
export var closeViewer = "photos-module--close-viewer--mkV3v";
export var mainPhotoContainer = "photos-module--main-photo-container--h8JRE";
export var mainPhoto = "photos-module--main-photo--DnJMP";
export var allPhotos = "photos-module--all-photos--KtId2";
export var outerImg = "photos-module--outer-img--lqzzQ";
export var eachPhoto = "photos-module--each-photo--Vp0-e";
export var eachPhotoImg = "photos-module--each-photo-img--hjbQk";
export var videoContainer = "photos-module--video-container--ysdDU";
export var textContainer = "photos-module--text-container--igUtP";