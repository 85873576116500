import * as React from 'react'
import { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { container, mainPhotoContainer, mainPhoto, allPhotos, outerImg, eachPhoto, eachPhotoImg, nextArrow, prevArrow, closeViewer } from './photos.module.css'

const PhotoViewer = ({ images }) => {
  const [mainImage,setMainImage] = useState(0);

  const hideViewer = () => {
    const isBrowser = () => typeof document !== "undefined";
    if (isBrowser()) {
      document.querySelector("#photoViewer").style.display = "none";
      setMainImage(0);
    }
  }
  const nextPhoto = () => {
    if (mainImage<images.length-1) { setMainImage(mainImage+1); }
    else { setMainImage(0); }
  };
  const prevPhoto = () => {
    if (mainImage>0) { setMainImage(mainImage-1); }
    else { setMainImage(images.length-1); }
  };
  const keys = (e) => {
    e.preventDefault();
    if (e.code==="ArrowRight") { nextPhoto(); }
    if (e.code==="ArrowLeft") { prevPhoto(); }
    if (e.code==="Escape") { hideViewer(); }
  }

  return (
    <div className={container} id="photoViewer" onKeyDown={keys} tabIndex="0" role="button">
      <div className={closeViewer} onClick={hideViewer} role="presentation"><svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M10,10 90,90 M10,90 90,10" />
      </svg></div>
      <div className={nextArrow} onClick={nextPhoto} role="presentation"><svg viewBox="0 0 50 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M5,5 45,50 5,95" />
      </svg></div>
      <div className={prevArrow} onClick={prevPhoto} role="presentation"><svg viewBox="0 0 50 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M45,5 5,50 45,95" />
      </svg></div>
      {(images.length>0)
        ? <GatsbyImage image={getImage(images[mainImage])} alt="Main Image"
        className={mainPhotoContainer} imgClassName={mainPhoto}
        objectFit="contain" loading="eager"></GatsbyImage>
        : <></>
      }
      <div className={allPhotos}>
        {images.map((img,i) => (
          <div className={outerImg} onClick={()=>(i!==mainImage)?setMainImage(i):null} key={`Photo ${i+1}`} role="presentation">
            <GatsbyImage image={getImage(img)} alt={`Photo ${i+1}`} objectFit="cover" className={eachPhoto} loading="eager"
            imgClassName={eachPhotoImg} imgStyle={(i===mainImage)?{border:"2px solid var(--color-highlight)",boxSizing:"border-box"}:{}}></GatsbyImage>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PhotoViewer
