import * as React from 'react'
import { container, closeViewer, textContainer } from './photos.module.css'

const TextViewer = ({ text, title }) => {
  const hideViewer = () => {
    const isBrowser = () => typeof document !== "undefined";
    if (isBrowser()) {
      document.querySelector("#textViewer").style.display = "none";
    }
  }
  const keys = (e) => {
    if (e.code==="Escape") { hideViewer(); }
  }

  return (
    <div className={container} id="textViewer" onKeyDown={keys} tabIndex="0" role="button">
      <div className={closeViewer} onClick={hideViewer} role="presentation"><svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M10,10 90,90 M10,90 90,10" />
      </svg></div>
      <div className={textContainer}>
        <h1>{title}</h1>
        <p>{text}</p>
        <footer>Copyright &copy; Evan Gedrich. All rights reserved.</footer>
      </div>
    </div>
  )
}

export default TextViewer
